import React, { useEffect, useState } from "react";
import { FormControlLabel, Grid, Checkbox, Button } from "@mui/material";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { TCInput, TCSelect } from "components/TopCourtComponents/Input/TopCourtInput";

import { courtTypeOption, dayOptions, timeOptions } from "constant/constant"
import DateRangeInput from "components/TopCourtComponents/Input/DateRangeFilter";
import { getCourts } from "services/placeServices";
import { courtTypeObj } from "./schedule";
import AsyncSelect from "react-select/async";
import { getPlayers } from "services/playerServices";
import { getAddOnCoach } from "services/addonServices";

const initForm = {
    courtTypeInfo: null,
    courtInfo: null,
    startDate: null,
    endDate: null,
    startHours: null,
    endHours: null,
    offDays: null,
    coachInfo: null,
    includeBallboy: false
}

function ClassScheduleInfo ({
    setClassCourt,
    classId,
    formData
}) {

    const [ typeInfo, setTypeInfo ] = useState("fixed")
    const [ courtsOptions, setCourtsOptions ] = useState([])
    const [ form, setForm ] = useState(initForm)
    const [ allowUpdate, setAllowUpdate ] = useState(false)
    const [JoiForm, setJoiForm] = useState({
        courtTypeInfo: null,
        courtInfo:  null,
        startDate: null,
        endDate: null,
        startHours: null,
        endHours: null,
        // coachInfo: null
    })

    
    useEffect(() => {
        setJoiForm({
            courtTypeInfo: form.courtTypeInfo? null : '*',
            courtInfo: form.courtInfo? null : '*',
            startDate: form.startDate? null : '*',
            endDate: form.endDate? null : '*',
            startHours: form.startHours? null : '*',
            endHours: form.endHours? null : '*',
            // coachInfo: form.coachInfo ? null : '*'
        })
    }, [form])

    useEffect(() => {
        if ((formData && !allowUpdate) || (formData && formData.doneSetCopy) || (formData && !formData.doneSet)) { 
            setTypeInfo(formData.courtSelection)
            let orderItems = []
            let offDays = []
            for (let i = 0; i < courtTypeOption.length; i ++) {
                let exist = formData.classSchedule.courtType.indexOf(courtTypeOption[i].value)
                if (exist >= 0) {
                    orderItems.push(courtTypeOption[i])
                }
            }
            for (let i = 0; i < dayOptions.length; i ++) {
                let exist = formData.classSchedule.offDays ? formData.classSchedule.offDays.indexOf(dayOptions[i].value) : -1
                if (exist >= 0) {
                    offDays.push(dayOptions[i])
                }
            }
            let endHours = null
            let nextHours = false
            for (let i = 0; i < timeOptions.length; i ++) {
                if (nextHours) { endHours = timeOptions[i]; break;}
                if (timeOptions[i].value == formData.classEndHours) { nextHours = true }
            }
            setForm({
                courtTypeInfo: orderItems,
                courtInfo: formData.classSchedule.court ? {
                    label: `${courtTypeObj[formData.classSchedule.court.courtType]} ${formData.classSchedule.court.name}`,
                    value: formData.classSchedule.court._id
                }
                 : null,
                startDate: formData.classSchedule.startClassDate,
                endDate: formData.classSchedule.endClassDate,
                startHours: formData.classStartHours ? {label: formData.classStartHours, value: formData.classStartHours} : null,
                endHours: formData.classEndHours ? endHours : null,
                offDays: offDays,
                coachInfo: formData.coaches,
                includeBallboy: formData.includeBallboy || false
            }) 
        }
        setAllowUpdate(true)
    }, [formData])

    useEffect(() => {
        if (form.courtTypeInfo) {
            loadCourtInfo()
        }
    }, [form.courtTypeInfo])

    useEffect(() => {
        if (allowUpdate == true) {
            setClassCourt({
                courtSelection: typeInfo,
                courtType: form.courtTypeInfo,
                court: form.courtInfo,
                startClassDate: form.startDate,
                endClassDate: form.endDate,
                offDays: form.offDays,
                classStartHours: form.startHours ? form.startHours.value : '',
                classEndHours: form.endHours ? form.endHours.value : '',
                coachInfo: form.coachInfo ? form.coachInfo : null,
                includeBallboy: form.includeBallboy
            })
        }
    }, [form])

    const loadCourtInfo = async () => {
        if (typeInfo == "dynamic") { return [] }
        let { data, error } = await getCourts("653f5d9006520fccd4ab4604")
        if (error) { console.log(error) }
        if (data) {
            console.log('here data courtInfo', data)
            let courts = []
            for (let i = 0; i < data.courts.length; i++) {
                if (data.courts[i].courtType == form.courtTypeInfo[0].value) {
                    courts.push({
                        label: `${courtTypeObj[data.courts[i].courtType]} ${data.courts[i].name}`,
                        value: data.courts[i]._id
                    })
                }
            }
            setCourtsOptions(courts)
        }
    }

    const loadTimeOptions = (isStartHours, isEndHours) => {
        let newHours = []
        let startHours = "06:00"
        let allowAddHours = false
        if (isStartHours) {
            for (let i = 0; i < timeOptions.length; i ++) {
                if (timeOptions[i].value == startHours) { allowAddHours = true }
                if (allowAddHours) { newHours.push(timeOptions[i]) }
            }
            newHours.pop()
            return newHours
        }
        let formStartHours = form.startHours
        if (isEndHours && formStartHours) {
            for (let i = 0; i < timeOptions.length; i ++) {
                if (allowAddHours) { newHours.push(timeOptions[i]) }
                if (timeOptions[i].value == formStartHours.value) { allowAddHours = true }
            }
            return newHours
        }
    }

    const loadUserOption = async (input) => {
        if (input.length < 3) {
            return
        }
        try {
            let { data, error } = await getAddOnCoach(input);
            if (error) throw error
            if (data) {
                if (data.length <= 0) return
                return data.map(value => {
                    return {
                        label: value.name,
                        value: value._id
                    }
                })
            }
        } catch (error) {
            console.log(error.message || error)
            return ([])
        }
      }

    const onChange = (e) => {
        setForm(prev => ({
            ...prev,
            ...e,
        }))
    }

    return (
        <Grid container xs={12} pt={0}>
            <Grid item container mb={1} xs={6}>
                <Grid item>
                    <FormControlLabel control={<Checkbox 
                        disabled={classId}
                        checked={typeInfo == "fixed"} 
                        onChange={() => {
                            setTypeInfo("fixed")
                            setForm(initForm)
                        }} 
                    />} label={<TCLabel bold>Fixed</TCLabel>} />
                </Grid>
                <Grid item>
                    <FormControlLabel control={<Checkbox 
                        disabled={classId}
                        checked={typeInfo == "dynamic"} 
                        onChange={() => {
                            setTypeInfo("dynamic")
                            setForm(initForm)
                        }} 
                    />} label={<TCLabel bold>Dynamic</TCLabel>} />
                </Grid>
                {/* <Grid item xs alignContent={"center"}>
                    <TCLabel>{typeInfo == "fixed" ? "Fixed court will only use selected court" : 
                    "Dynamic court will get available court for the class"}</TCLabel>
                </Grid> */}
            </Grid>
            <Grid item container mb={1} xs={6}>
                <Grid item>
                    <FormControlLabel control={<Checkbox 
                        // disabled={classId}
                        checked={form.includeBallboy} 
                        onChange={() => {
                            setForm(prev => ({
                                ...prev,
                                includeBallboy: !form.includeBallboy
                            }))
                        }} 
                    />} label={<TCLabel>Ballboy (Random Pick)</TCLabel>} />
                </Grid>
            </Grid>
            {/* Fixed */}
            {
                typeInfo == "fixed" &&
                <Grid item container xs={12} mb={1}>
                    <Grid item container xs={12} spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Grid container alignItems={'flex-end'} spacing={1}>
                                <Grid item>
                                    <TCLabel>Court Type</TCLabel>
                                </Grid>
                                <Grid item sx={{ pt: '0px !important', pl: '2px !important' }}>
                                {JoiForm.courtTypeInfo ?
                                    <TCLabel style={{ fontSize: 11, color: '#f44336' }}>{JoiForm.courtTypeInfo}</TCLabel>
                                    :''
                                }
                                </Grid>
                            </Grid>
                            <TCSelect showAlert={JoiForm && JoiForm.courtType}
                                options={courtTypeOption}
                                disabled={classId}
                                value={form.courtTypeInfo ? form.courtTypeInfo[0] : null}
                                // isMulti={true}
                                onChange={(e) => {
                                    onChange({ courtTypeInfo: [e], courtInfo: null })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container alignItems={'flex-end'} spacing={1}>
                                <Grid item>
                                    <TCLabel>Court</TCLabel>
                                </Grid>
                                <Grid item sx={{ pt: '0px !important', pl: '2px !important' }}>
                                {JoiForm.courtInfo ?
                                    <TCLabel style={{ fontSize: 11, color: '#f44336' }}>{JoiForm.courtInfo}</TCLabel>
                                    :''
                                }
                                </Grid>
                            </Grid>
                            <TCSelect
                                options={courtsOptions}
                                disabled={classId}
                                value={form.courtInfo}
                                onChange={(e) => {
                                    onChange({ courtInfo: e })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateRangeInput
                                disabledVariable
                                showLabel={true}
                                showAlert={JoiForm.startDate || JoiForm.endDate}
                                disabledStart={classId}
                                startDate={form.startDate}
                                startDateMinDate={new Date()}
                                endDate={form.endDate}
                                endDateMinDate={form.startDate ? form.startDate : new Date()}
                                onChangeStartDate={(e) => onChange({ startDate: e, endDate: null })}
                                onChangeEndDate={(e) => onChange({ endDate: e })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container alignItems={'flex-end'} spacing={1}>
                                <Grid item>
                                    <TCLabel>Hours</TCLabel> 
                                </Grid>
                                <Grid item sx={{ pt: '0px !important', pl: '2px !important' }}>
                                {JoiForm.startHours || JoiForm.endHours ?
                                    <TCLabel style={{ fontSize: 11, color: '#f44336' }}>{JoiForm.startHours || JoiForm.endHours}</TCLabel>
                                    :''
                                }
                                </Grid>
                            </Grid>
                            <Grid item container spacing={1}>
                                <Grid item xs>
                                    <TCSelect
                                        options={loadTimeOptions(true, false)}
                                        disabled={classId}
                                        value={form.startHours}
                                        onChange={(e) => {
                                            onChange({ startHours: e, endHours: null })
                                        }}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <TCSelect
                                        options={loadTimeOptions(false, true)}
                                        disabled={classId}
                                        value={form.endHours}
                                        onChange={(e) => {
                                            onChange({ endHours: e })
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            {/* <TCSelect
                                options={courtTypeOption}
                            /> */}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TCLabel>Off Days</TCLabel> 
                            <Grid item xs>
                                <TCSelect
                                    isMulti={true}
                                    disabled={classId}
                                    options={dayOptions}
                                    value={form.offDays}
                                    onChange={(e) => {
                                        onChange({ offDays: e })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12} textAlign={"right"}>
                            <Button variant="contained" onClick={() => {
                                console.log(form)
                            }}>
                                Add
                            </Button>
                        </Grid> */}
                    </Grid>
                </Grid>
            }
            {/* Dynamic */}
            {
                typeInfo == "dynamic" &&
                <Grid item container xs={12} mb={1}>
                    <Grid item container xs={12} spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Grid container alignItems={'flex-end'} spacing={1}>
                                <Grid item>
                                    <TCLabel>Court Type</TCLabel>
                                </Grid>
                                <Grid item sx={{ pt: '0px !important', pl: '2px !important' }}>
                                {JoiForm.courtTypeInfo ?
                                    <TCLabel style={{ fontSize: 11, color: '#f44336' }}>{JoiForm.courtTypeInfo}</TCLabel>
                                    :''
                                }
                                </Grid>
                            </Grid>
                            <TCSelect
                                options={courtTypeOption}
                                value={form.courtTypeInfo}
                                disabled={classId}
                                isMulti={true}
                                onChange={(e) => {
                                    onChange({ courtTypeInfo: e })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container alignItems={'flex-end'} spacing={1}>
                                <Grid item>
                                    <TCLabel>Coach</TCLabel>
                                </Grid>
                                <Grid item sx={{ pt: '0px !important', pl: '2px !important' }}>
                                {/* {JoiForm.coachInfo ?
                                    <TCLabel style={{ fontSize: 11, color: '#f44336' }}>{JoiForm.coachInfo}</TCLabel>
                                    :''
                                } */}
                                </Grid>
                            </Grid>
                            <AsyncSelect
                                placeholder={'Select Coach'}
                                isClearable
                                async
                                defaultOptions
                                loadOptions={loadUserOption}
                                isMulti={true}
                                value={form.coachInfo} onChange={(e) => { onChange({ coachInfo: e }) }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateRangeInput
                                disabledVariable
                                showLabel={true}
                                showAlert={JoiForm.startDate || JoiForm.endDate}
                                disabledStart={classId}
                                startDate={form.startDate}
                                startDateMinDate={new Date()}
                                endDate={form.endDate}
                                endDateMinDate={form.startDate ? form.startDate : new Date()}
                                onChangeStartDate={(e) => onChange({ startDate: e, endDate: null })}
                                onChangeEndDate={(e) => onChange({ endDate: e })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>  
                            <Grid container alignItems={'flex-end'} spacing={1}>
                                <Grid item>
                                    <TCLabel>Hours</TCLabel> 
                                </Grid>
                                <Grid item sx={{ pt: '0px !important', pl: '2px !important' }}>
                                {JoiForm.startHours || JoiForm.endHours ?
                                    <TCLabel style={{ fontSize: 11, color: '#f44336' }}>{JoiForm.startHours || JoiForm.endHours}</TCLabel>
                                    :''
                                }
                                </Grid>
                            </Grid>
                            <Grid item container spacing={1}>
                                <Grid item xs>
                                    <TCSelect
                                        disabled={classId}
                                        options={loadTimeOptions(true, false)}
                                        value={form.startHours}
                                        onChange={(e) => {
                                            onChange({ startHours: e, endHours: null })
                                        }}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <TCSelect
                                        disabled={classId}
                                        options={loadTimeOptions(false, true)}
                                        value={form.endHours}
                                        onChange={(e) => {
                                            onChange({ endHours: e })
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TCLabel>Off Days</TCLabel> 
                            <Grid item xs>
                                <TCSelect
                                    isMulti={true}
                                    disabled={classId}
                                    options={dayOptions}
                                    value={form.offDays}
                                    onChange={(e) => {
                                        onChange({ offDays: e })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12} textAlign={"right"}>
                            <Button variant="contained" onClick={() => {
                                console.log(form)
                            }}>
                                Add
                            </Button>
                        </Grid> */}
                    </Grid>
                </Grid>
            }
        </Grid>
    )
}

export default ClassScheduleInfo