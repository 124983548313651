import React, { useState } from 'react';

// material-ui
import { Button, Switch, Tooltip } from '@mui/material';

import { currencyFormat } from 'helper/NumberFormat';

import { getPackages, updatePackageIndex } from 'services/packageServices';
import { useEffect } from 'react';
import { TCLabel } from 'components/TopCourtComponents/Label/TopCourtLabel';
import TableComponent from 'components/TopCourtComponents/Table/TableComponent';
import moment from 'moment';
import PackageFilter from './packageFilter';
import { TypeSelectOption } from 'components/TopCourtComponents/Input/NominalRangeFilter';
import { useDebounce } from 'use-debounce';
import { useNavigate } from 'react-router';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import TocIcon from '@mui/icons-material/Toc';
import TableV2Component from 'components/TopCourtComponents/Table/Tablev2Component';
import { trueFalseOptions } from 'constant/constant';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const initFilter = {
    name: '',
    sessionType: TypeSelectOption[0],
    sessionStart: '',
    sessionEnd: '',
    priceType: TypeSelectOption[0],
    priceStart: '',
    priceEnd: '',
    isActive: trueFalseOptions[0],
    sort: null
}

export const courtTypeObj = {
    all_court: "All Court",
    indoor: "Indoor",
    outdoor: "Outdoor",
    hitting_room: "Hitting"
}

export default function PackageList({

}) {

    const [rows, setRows] = useState([])
    const [filter, setFilter] = useState(initFilter)
    const [debouncedFilter] = useDebounce(filter, 500)
    const [isSort, setIsSort] = useState(false)
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            loadData()
        })()
    }, [debouncedFilter])

    const loadData = async () => {
        setLoading(true)
        let { data, error } = await getPackages({
            name: filter.name? filter.name : '',
            priceType:filter.priceType? filter.priceType.value : '',
            priceStart:filter.priceStart? filter.priceStart : '',
            priceEnd: filter.priceEnd? filter.priceEnd : '',
            sessionType:filter.sessionType? filter.sessionType.value : '',
            sessionStart:filter.sessionStart? filter.sessionStart : '',
            sessionEnd: filter.sessionEnd? filter.sessionEnd : '',
            isActive: filter.isActive ? filter.isActive.value : '',
            sort: filter.sort ? JSON.stringify(filter.sort) : ''
        });
        setLoading(false)
        if (error) { console.log(error) }
        if (data) { setRows(data) }
    }

    const valueCustomatorSort = (row) => {
        return ({
            createdDate: <div style={{ display: 'flex' }}>{isSort && <DragIndicatorIcon sx={{ color: 'gray' }} />}<TCLabel style={{ marginTop: 4, marginLeft: 4 }}>{moment(row.createdDate).format("YYYY/MM/DD HH:mm:ss")}</TCLabel></div>,
            name: <span className='span-link' onClick={() => {navigate(`/packages/edit/${row._id}`)}}>{row.name}</span>,
            sessions: <TCLabel>{`${courtTypeObj[row.court]} ${row.totalSession}`}</TCLabel>,
            price: <TCLabel>{currencyFormat(row.price)}</TCLabel>,
            advancedMonth: <TCLabel>Month + {row.advancedMonths}</TCLabel>,
            sessionValidity: <TCLabel>{row.validity} Month</TCLabel>,
            limitGlobal: <TCLabel>{row.maxPackages ? `${row.usage ? row.usage : 0}/${row.maxPackages}` : "-"}</TCLabel>,
        })
    }

    const valueCustomator = (row) => {
        return ({
            createdDate: <TCLabel>{moment(row.createdDate).format("YYYY/MM/DD HH:mm:ss")}</TCLabel>,
            name: <span className='span-link' onClick={() => {navigate(`/packages/edit/${row._id}`)}}>{row.name}</span>,
            sessions: <TCLabel>{`${courtTypeObj[row.court]} ${row.totalSession}`}</TCLabel>,
            price: <TCLabel>{currencyFormat(row.price)}</TCLabel>,
            advancedMonth: <TCLabel>Month + {row.advancedMonths}</TCLabel>,
            sessionValidity: <TCLabel>{row.validity} Month</TCLabel>,
            limitGlobal: <TCLabel>{row.maxPackages ? `${row.usage ? row.usage : 0}/${row.maxPackages}` : "-"}</TCLabel>,
            status: <Switch checked={row.isActive} /> 
        })
    }

    const updateValuePackIndex = async (e) => {
        try {
            console.log(e)
            // map the data first
            let body = []
            for (let i = 0; i < e.length; i ++) {
                body.push({
                    _id: e[i]._id,
                    index: i
                })
            }
            let { data, error } = await updatePackageIndex({
                indexData: body
            })
            if (error) { throw error }
        } catch (error) {
            console.log(error)
        }
    }

    const onChangeFilter = (e) => {
        setFilter(prev => ({
            ...prev,
            ...e
        }))
    }

    const clearFilter = () => {
        setFilter(initFilter)
    }

    return (
        <>
            <Tooltip title={isSort ? "Sort value pack" : "List Value Pack"}>
                <Button sx={{ mb: 2, mr: 1}} variant="contained" disableElevation
                    onClick={() => {
                        setLoading(true)
                        setIsSort(!isSort)
                        setFilter(prev => ({
                            ...initFilter,
                            isActive: (!isSort === true) ? trueFalseOptions[1] : trueFalseOptions[0]
                        }))
                        setRows([])
                    }}
                >
                    {!isSort ? 
                        <SwapVertIcon/>
                        :
                        <TocIcon/>
                    }
                </Button>
            </Tooltip>
            <Button sx={{ mb: 2 }} variant="contained" disableElevation onClick={() => {
                navigate("/packages/add")
            }}>Add New Package</Button>
            {
                !isSort && 
                <PackageFilter filter={filter} onChangeFilter={onChangeFilter} clearFilter={clearFilter} />
            }
            {
                isSort ?
                <TableV2Component
                    columns={[
                        { label: "Created Date" },
                        { label: "Package Name" },
                        { label: "Session" },
                        { label: "Price" },
                        { label: "Advanced Booking" },
                        { label: "Session Validity" },
                        { label: "Limit Global / Month" },
                    ]}
                    rows={rows}
                    valueCustomator={valueCustomatorSort}
                    onLoading={loading}
                    onDragEnd={(e) => {
                        updateValuePackIndex(e)
                    }}
                />
                :
                <TableComponent
                    columns={[
                        { label: "Created Date", allowSort: true, sortValue: 'createdDate' },
                        { label: "Package Name" },
                        { label: "Session" },
                        { label: "Price" },
                        { label: "Advanced Booking" },
                        { label: "Session Validity" },
                        { label: "Limit Global / Month" },
                        { label: "Status" },
                    ]}
                    rows={rows}
                    loading={loading}
                    valueCustomator={valueCustomator}
                    sort={(e) => {
                        let sortJSON = filter.sort ? filter.sort : {[e.sortValue] : 1}
                        if (filter.sort) {
                            console.log(Object.keys(filter.sort)[0], Object.keys(sortJSON)[0])
                            if (Object.keys(filter.sort)[0] == Object.keys(sortJSON)[0]) {
                                sortJSON = {[e.sortValue] : (sortJSON[Object.keys(sortJSON)[0]] * -1)}
                            }
                        }
                        setFilter(prev => ({
                            ...prev,
                            sort: sortJSON
                        }))
                    }}
                />
            }
        </>
    )
}