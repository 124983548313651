import React from "react";

import { Box, Button, Card, Dialog, Grid, Typography } from '@mui/material';
import { TCInput, TCSelect } from "components/TopCourtComponents/Input/TopCourtInput";
import { getPlayers } from "services/playerServices";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { orderStatusOption, orderTypeOption, trueFalseOptions } from "constant/constant";
import NumberRangeInput from "components/TopCourtComponents/Input/NominalRangeFilter";

export default function PackageFilter({
    filter,
    onChangeFilter,
    clearFilter
}) {
    return (
        <Card sx={{ mb: 1 }}
            className="custom-card-style">
            <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} md={2} sx={{ mb: 1 }}>
                        <TCInput placeHolder="Name" onChange={(e) => onChangeFilter({ name: e.currentTarget.value })} value={filter.name} label={"Package Name"} />
                    </Grid>
                    <Grid item xs={12} md={3} sx={{ mb: 1 }}>
                        <NumberRangeInput
                            showLabel={true}
                            label={"Session"}
                            placeholderStart={'Min'}
                            placeholderEnd={'Max'}
                            allowZero={false}
                            numberRangeType={filter.sessionType}
                            start={filter.sessionStart}
                            end={filter.sessionEnd}
                            onChangeSelect={(e) => onChangeFilter({ sessionType: e })}
                            onChangeStart={(e) => onChangeFilter({ sessionStart: e.replace(/[^0-9]+/ig, '') })}
                            onChangeEnd={(e) => onChangeFilter({ sessionEnd: e.replace(/[^0-9]+/ig, '') })}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} sx={{ mb: 1 }}>
                        <NumberRangeInput
                            showLabel={true}
                            label={"Price"}
                            placeholderStart={'Min'}
                            placeholderEnd={'Max'}
                            allowZero={false}
                            numberRangeType={filter.priceType}
                            start={filter.priceStart}
                            end={filter.priceEnd}
                            onChangeSelect={(e) => onChangeFilter({ priceType: e })}
                            onChangeStart={(e) => onChangeFilter({ priceStart: e.replace(/[^0-9]+/ig, '') })}
                            onChangeEnd={(e) => onChangeFilter({ priceEnd: e.replace(/[^0-9]+/ig, '') })}
                        />
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ mb: 1 }}>
                        <TCSelect
                            label={"Active"}
                            options={trueFalseOptions}
                            value={filter.isActive}
                            onChange={(e) => {
                                onChangeFilter({
                                    isActive: e
                                })
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ mb: 1, textAlign:'right' }} >
                        <Button sx={{mt: 2, border: '0.5px solid #2196f3', borderRadius: 4}}
                            onClick={clearFilter}
                        >Clear</Button>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    )
}
