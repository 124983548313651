import React, { useEffect, useState } from 'react';

import {
  DragDropContext,
  Droppable,
  Draggable
} from "react-beautiful-dnd";

import BlockUi from "react-block-ui";
import { Table, TableCell, TableHead, TableRow, TableFooter, Paper } from '@mui/material';
import { TCLabel } from '../Label/TopCourtLabel';

export default function TableV2Component({
  rows = [],
  columns,
  valueCustomator,
  customDragHandler = false,
  styles,
  // dnd
  onDragEnd,
  onLoading,
  droppableId = '1'
}) {

  const [localRows, setLocaRows] = useState([]);

  useEffect(() => {
    setLocaRows(rows);
  }, [rows]);

  const handleOnDragEnd = (result) => {
    try {
      // [source]: https://egghead.io/lessons/react-persist-list-reordering-with-react-beautiful-dnd-using-the-ondragend-callback
      const { destination, source } = result;

      if (!destination) return;

      // check location change
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) return;

      // reoder 
      const newRows = Array.from(localRows);
      const [removed] = newRows.splice(source.index, 1);
      newRows.splice(destination.index, 0, removed);
      setLocaRows(newRows);
      onDragEnd(newRows);

    } catch (error) {
      console.error(error);
    }
  }


  // ### ui
  // for no data
  const getEmptyRow = () =>
    <tbody>
      <TableRow>
        <TableCell align="center" colSpan={columns.length} style={{ height: 100 }}></TableCell>
      </TableRow>
    </tbody>

  // mapping data base from column
  const getTableColumn = (row) => (
    columns && columns.map((col, indexCol) => {
      // add aditional props
      let props = {};
      if (col.onCellClicked) {
        props = {
          onClick: () => col.onCellClicked(row),
          style: {
            cursor: 'pointer'
          }
        }
      }
      return (
        <TableCell key={indexCol} {...props}>
          {col.valueCustomator ? col.valueCustomator(row, ) : <div>{row[col.name]}</div>}
        </TableCell>
      )
    })
  );
  // customDraggableProps
  const getCustomTableColumn = (row, draggableProps, draggableHandleProps, snapshot) => (
    columns && columns.map((col, indexCol) => {
      // add aditional props
      let props = {};
      if (col.onCellClicked) {
        props = {
          onClick: () => col.onCellClicked(row),
          style: {
            cursor: 'pointer'
          }
        }
      }
      return (
        <TableCell key={indexCol} {...props}>
          {col.valueCustomator(row, {...draggableProps}, {...draggableHandleProps}, snapshot)}
        </TableCell>
      )
    })
  );

  // head
  const getTableHead = () => {
    if (!(columns && columns.length > 0)) return;
    return (
      <TableHead>
        <TableRow>
          {columns.map((head, index) => <TableCell key={index}>{head.title ? head.title : ''}</TableCell>)}
        </TableRow>
      </TableHead>
    )
  }

  // body for dnd
  const getTableBodyDnD = () => {
    try {
      if (!(localRows && localRows.length > 0)) return getEmptyRow();
      return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId={droppableId || '1'}>
            {(provided) => (
              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {localRows.map((row, indexRow) =>
                  <Draggable key={row._id} draggableId={row._id + ''} index={indexRow}>
                    {(providedDrag, snapshot) => (
                      !customDragHandler ? (
                        <tr
                          key={row._id}
                          ref={providedDrag.innerRef}
                          {...providedDrag.dragHandleProps}
                          {...providedDrag.draggableProps}
                          style={{ ...providedDrag.draggableProps.style, background: snapshot.isDragging ? "rgba(245,245,245, 0.75)" : "" }}
                        >
                          {/* {getTableColumn(row)} */}
                          {
                              Object.keys(valueCustomator(row)).map(value => {
                                  return <TableCell>{valueCustomator(row)[value]}</TableCell>
                              })
                          }
                        </tr>
                      ) : (
                        <tr
                          key={row._id}
                          ref={providedDrag.innerRef}
                          style={{ ...providedDrag.draggableProps.style, background: snapshot.isDragging ? "rgba(245,245,245, 0.75)" : "" }}
                        >
                          {/* {getCustomTableColumn(row, {...providedDrag.draggableProps}, {...providedDrag.dragHandleProps}, snapshot)} */}
                          {
                              Object.keys(valueCustomator(row)).map(value => {
                                  return <TableCell>{valueCustomator(row)[value]}</TableCell>
                              })
                          }
                        </tr>
                      )
                    )}
                  </Draggable>
                )}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      )
    } catch (error) {
      console.error(error);
    }
  }
  // [PENDING]
  const getTableFooter = () =>
    <TableFooter>
      <TableRow>
        {/* <TablePagination
          rowsPerPageOptions={[1, 10, 25]}
          colSpan={3}
          count={rows.length}
          rowsPerPage={1}
          page={1}
          SelectProps={{
            native: true,
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActionsWrapped}
        /> */}
      </TableRow>
    </TableFooter>

  return (
    <Paper style={{ overflowX: 'auto', width: '100%', ...styles }}>
      <BlockUi tag="div" blocking={onLoading}>
        <Table sx={{
            ".MuiTableCell-root": {
                minWidth: "120px",
            },
        }} aria-label="simple table">
          <TableHead>
              <TableRow>
                  {
                      columns.map(value => {
                          return <TableCell><TCLabel bold>{value.label}</TCLabel></TableCell>
                      })
                  }
              </TableRow>
          </TableHead>
          {getTableBodyDnD()}
          {/* {getTableFooter()} */}
        </Table>
      </BlockUi>
    </Paper>
  )
}